import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import AIImpactScorePage from "./AIImpactScorePage/AIImpactScorePage";
import WorkshopComponent from "./WorkshopComponent/WorkshopComponent";
import AuthContext from "../../../../store/auth-context";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {
   AugmentationReportDataRepresentationDto,
   ExtractedSkillsDto, FeedbackDto,
   ToolDto, ToolFeedbackUses,
   ToolInformation
} from "../../../../types/AugmentationData";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../services/api.service";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {usePostHog} from "posthog-js/react";
import TooltipModal from "./TooltipModal/TooltipModal";

const AIImpactScoreRoutes: React.FC<{}> = props => {

   const [workshopSkills, setWorkshopSkills] = useState<ExtractedSkillsDto[]>([]);
   const [augmentationData, setAugmentationData] = useState<AugmentationReportDataRepresentationDto>();
   const [selectedTool, setSelectedTool] = useState<{tool: ToolDto, information?: ToolInformation, errorLoading: boolean, impactScore?: {old: number, new: number}}>();

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const location = useLocation();
   const posthog = usePostHog();

   useEffect(() => {
      authStore.userData.code && sendCodeForWorkshop(authStore.userData.code, authStore.userData.accessToken);
      authStore.channelRegister.unbind(`augmentation_${authStore.userData.userId}`);
      authStore.channelRegister.bind(`augmentation_${authStore.userData.userId}`, (_data: { message: string }) => {
         initializeComponent(authStore.userData.accessToken, true);
      });
      authStore.changePageTitle("AI Impact Score");
      initializeComponent(authStore.userData.accessToken);
   }, []);

   useEffect(() => {
      posthog.capture('$pageview', {
         path: location.pathname,
      });
   }, []);

   return (
      <>
         <Routes>
            {
               augmentationData &&
               <Route path={"*"} element={<AIImpactScorePage augmentationData={augmentationData} sendCodeForWorkshop={sendCodeForWorkshop} subscribeToNews={subscribeToNews} showToolModal={showToolModal} selectedTool={selectedTool} />} />
            }
            {
               authStore.userData.code &&
               <Route path={"/workshop"} element={<WorkshopComponent skills={workshopSkills} showToolModal={showToolModal} />} />
            }
         </Routes>
         {
            spinnerService.spinner
         }
         {
            selectedTool && selectedTool.information &&
            <TooltipModal tool={selectedTool as {tool: ToolDto, information: ToolInformation, errorLoading: boolean, impactScore?: {old: number, new: number}}}
                          close={() => setSelectedTool(undefined)}
                          submit={(tool, option) => sendToolFormFeedback(tool, option, authStore.userData.accessToken)} />
         }
      </>
   );

   function initializeComponent(accessToken: string, fromPusher?: boolean) {
      const getAugmentationReportURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      !fromPusher && spinnerService.createSpinner();

      axios
         .post(getAugmentationReportURL, undefined, {headers})
         .then((response$: AxiosResponse<AugmentationReportDataRepresentationDto>) => {
            setAugmentationData(response$.data);
            !fromPusher && spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               !fromPusher && spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function sendCodeForWorkshop(code: string, accessToken: string, navigation?: boolean) {
      const sendCodeForWorkshopURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/workshop-view';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         code
      }

      navigation && spinnerService.createSpinner();

      axios
         .post(sendCodeForWorkshopURL, data, {headers})
         .then((response$: AxiosResponse<ExtractedSkillsDto[]>) => {
            authStore.updateCode(code);
            setWorkshopSkills(response$.data);
            navigation && navigate("/augmentation/augmentation-report/workshop");
            navigation && spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendCodeForWorkshop(code, response$.data.accessToken, navigation);
                  })
            } else {
               navigation && spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function subscribeToNews(accessToken: string) {
      const subscribeToNewsURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/subscribe';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(subscribeToNewsURL, undefined, {headers})
         .then((response$: AxiosResponse<boolean>) => {
            setAugmentationData((prevState: any) => {
               return {
                  ...prevState,
                  augmentationNotification: response$.data
               }
            });
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     subscribeToNews(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getToolInformation(tool: ToolDto, accessToken: string) {
      const getToolInformationURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/tool-information';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId: tool.toolId
      }

      axios
         .post(getToolInformationURL, data, {headers})
         .then((response$: AxiosResponse<ToolInformation>) => {
            setSelectedTool(prevState => {
               return prevState ? {
                  ...prevState,
                  information: response$.data
               } : undefined
            });
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getToolInformation(tool, response$.data.accessToken);
                  })
            } else {
               setSelectedTool(undefined);
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function sendToolFormFeedback(tool: ToolDto, option: ToolFeedbackUses, accessToken: string) {
      const sendToolFormFeedbackURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/save-tool-feedback';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId: tool.toolId,
         feedback: option
      }

      setSelectedTool(prevState => {
         return prevState ? {...prevState, errorLoading: false} : undefined;
      });

      axios
         .post(sendToolFormFeedbackURL, data, {headers})
         .then((response$: AxiosResponse<{aiImpactScore: {aiImpactScore: number}, toolFeedback: FeedbackDto}>) => {
            setSelectedTool((prevState: any) => {
               return prevState ? {
                  ...prevState,
                  information: {
                     ...prevState.information,
                     feedback: response$.data.toolFeedback ? response$.data.toolFeedback : undefined
                  },
                  impactScore: {
                     old: augmentationData!.aiImpactScore,
                     new: response$.data.aiImpactScore.aiImpactScore
                  },
               } : undefined
            });
            setAugmentationData(prevState => {
               prevState!.likeableAugmentedSkills.forEach(skill => {
                  if (skill.tools) {
                     skill.tools.forEach(toolItem => {
                        if (toolItem.toolId === tool.toolId) {
                           toolItem.feedback = { feedback: option };
                        }
                     });
                  }
               });
               prevState!.disLikeableAugmentedSkills.forEach(skill => {
                  if (skill.tools) {
                     skill.tools.forEach(toolItem => {
                        if (toolItem.toolId === tool.toolId) {
                           toolItem.feedback = { feedback: option };
                        }
                     });
                  }
               });
               return prevState ? {
                  ...prevState,
                  aiImpactScore: response$.data.aiImpactScore.aiImpactScore
               } : undefined;
            });
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendToolFormFeedback(tool, option, response$.data.accessToken);
                  })
            } else {
               setSelectedTool(prevState => {
                  return prevState ? {...prevState, errorLoading: true} : undefined;
               });
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function showToolModal(tool: ToolDto) {
      if (selectedTool?.tool) {
         return; // case when user selects one tool, and we don't want him to click on other tools and make 100 api calls
      }
      setSelectedTool({tool: tool, information: undefined, errorLoading: false});
      getToolInformation(tool, authStore.userData.accessToken);
   }

}

export default AIImpactScoreRoutes;