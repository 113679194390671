import React, {useContext, useEffect, useRef, useState} from "react";
import style from './AnnotatorPage.module.scss';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import AuthContext from "../../../store/auth-context";
import useApiService from "../../../services/api.service";
import useLoadingSpinner from "../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../types/ErrorData";
import {showNotification} from "../../../ui/Toast/ToastNotification";
import {
   AiAnnotatingSkillsDataDto,
   AiAnnotatingToolsDataDto,
   AnnotatedToolDataRequestDto
} from "../../../types/AnnotatorData";
import {TabulatorFull as Tabulator} from "tabulator-tables";
const AnnotatorPage: React.FC<{}> = props => {

   const grid = useRef<any>();
   const annotatorRef = useRef<any>(null);
   const filterInputRef = useRef<HTMLInputElement>(null);
   const [toolAndSkills, setToolAndSkills] = useState<AiAnnotatingToolsDataDto>({
      toolName: '',
      toolDescription: '',
      category: '',
      skills: []
   });
   const [skillsMap, setSkillsMap] = useState(new Map<string, 'yes' | 'no' | 'choose'>());

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      authStore.changePageTitle('Annotator Panel');
      initializeComponent(authStore.userData.accessToken);

      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
         event.preventDefault();
         event.returnValue = ''; // This is required for most browsers to show the warning dialog
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, []);

   useEffect(() => {
      if (toolAndSkills) {
         const newSkillsMap = new Map<string, 'yes' | 'no' | 'choose'>()
         toolAndSkills.skills.forEach(s => newSkillsMap.set(s.skillAiId, 'choose'));
         setSkillsMap(newSkillsMap);
      }
   }, [toolAndSkills]);

   useEffect(() => {

      const gridColumnsDefinition: any = [
         {title: "Skill AI Id", field: "skillAiId", minWidth: 200, headerSortTristate: true, validator: ["required", "unique"], visible: false},
         {title: "Skill Name", field: "skillName", minWidth: 200, headerSortTristate: true},
         {title: "Skill Description", field: "skillDescription", minWidth: 300, maxWidth: 500, headerSortTristate: true,
            formatter: (cell: any) => {
               let cellData: AiAnnotatingSkillsDataDto = cell.getData();
               let wrapper = document.createElement('div');

               let button = document.createElement('button');
               button.title = "Show more of the description";
               button.innerText = "show more";
               button.classList.add('button', 'button-tertiary', 'read-more-button');
               if (cellData.skillDescription.length < 200) {
                  button.hidden = true;
               }

               let text = document.createElement('span');
               text.innerHTML = cellData.skillDescription.slice(0, 150);

               wrapper.appendChild(text);
               wrapper.appendChild(button);

               // Event listener for 'Read more'
               button.addEventListener('click', () => {
                  if (!button.classList.contains("opened")) {
                     button.classList.toggle("opened");
                     text.innerHTML = cellData.skillDescription;
                     button.title = "Show less of the description";
                     button.innerText = "show less";
                  }
                  else {
                     button.classList.toggle("opened");
                     text.innerHTML = cellData.skillDescription.slice(0, 150);
                  }
                  cell.getRow().normalizeHeight();
               });

               return wrapper;
            }
         },
         {
            title: 'Is the tool useful for this skill', field: "actionButtons", headerSort: false, minWidth: 100, frozen: true,
            formatter: (cell: any) => {

               let cellData: AiAnnotatingSkillsDataDto = cell.getData();

               let wrapper = document.createElement('div');
               wrapper.classList.add('row-actions');

               let checkboxWrapperYes = document.createElement('div');
               checkboxWrapperYes.classList.add('checkbox-wrapper');

               let checkboxWrapperNo = document.createElement('div');
               checkboxWrapperNo.classList.add('checkbox-wrapper');

               let buttonYes = document.createElement('button');
               buttonYes.innerHTML = "Yes";
               buttonYes.classList.add('button', 'button-tertiary');

               let checkboxYes = document.createElement("div");
               checkboxYes.classList.add("radio-dark");
               let checkItemYes = document.createElement("i");
               checkItemYes.classList.add("fa-solid", "fa-circle");
               checkboxYes.appendChild(checkItemYes);

               let buttonNo = document.createElement('button');
               buttonNo.innerHTML = "No";
               buttonNo.classList.add('button', 'button-tertiary');

               let checkboxNo = document.createElement("div");
               checkboxNo.classList.add("radio-dark");
               let checkItemNo = document.createElement("i");
               checkItemNo.classList.add("fa-solid", "fa-circle");
               checkboxNo.appendChild(checkItemNo);

               const updateSkillsMap = (skillAiId: string, status: 'yes' | 'no', activeButton: HTMLElement, inactiveButton: HTMLElement, activeCheckbox: HTMLElement, inactiveCheckbox: HTMLElement) => {
                  setSkillsMap(prevState => {
                     const newSkillsMap = new Map<string, 'yes' | 'no' | 'choose'>(prevState);
                     newSkillsMap.set(skillAiId, status);
                     activeButton.classList.add('active');
                     inactiveButton.classList.remove('active');
                     activeCheckbox.classList.add('checked');
                     inactiveCheckbox.classList.remove('checked');
                     return newSkillsMap;
                  });
               };

               buttonYes.addEventListener('click', () => {
                  updateSkillsMap(cellData.skillAiId, 'yes', buttonYes, buttonNo, checkboxYes, checkboxNo);
               });

               checkboxYes.addEventListener('click', () => {
                  updateSkillsMap(cellData.skillAiId, 'yes', buttonYes, buttonNo, checkboxYes, checkboxNo);
               });

               buttonNo.addEventListener('click', () => {
                  updateSkillsMap(cellData.skillAiId, 'no', buttonNo, buttonYes, checkboxNo, checkboxYes);
               });

               checkboxNo.addEventListener('click', () => {
                  updateSkillsMap(cellData.skillAiId, 'no', buttonNo, buttonYes, checkboxNo, checkboxYes);
               });

               checkboxWrapperYes.appendChild(checkboxYes);
               checkboxWrapperYes.appendChild(buttonYes);
               checkboxWrapperNo.appendChild(checkboxNo);
               checkboxWrapperNo.appendChild(buttonNo);
               wrapper.appendChild(checkboxWrapperYes);
               wrapper.appendChild(checkboxWrapperNo);

               return wrapper;
            }
         }
      ];

      grid.current = new Tabulator(annotatorRef.current, {
         data: toolAndSkills.skills,
         columns: gridColumnsDefinition,
         index: "skillAiId",
         headerSortClickElement: "icon",
         layout: "fitColumns",
         validationMode: "highlight",
         headerSortElement: function (column, dir) {
            switch (dir) {
               case "asc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               case "desc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               default:
                  return "<span class='material-symbols-outlined'>sort</span>";
            }
         }
      });

      return () => {
         grid.current.destroy();
      }
   }, [toolAndSkills]);

   return (
      <>
         {
            toolAndSkills &&
            <div className={style['annotator-page']}>
               <div className="tool-container">
                  <h3 className="header-3">Tool name: {toolAndSkills.toolName}</h3>
                  <p className="tool-description text-surface-10">Tool description: {toolAndSkills.toolDescription}</p>
                  <p className="text-surface-10">Category: {toolAndSkills.category}</p>
                  <button className="button button-primary" onClick={() => sendToolAndSkillFeedback(false, authStore.userData.accessToken)}>This is not a tool</button>
               </div>
               <div className="tabs-container">
                  <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                     <TabList>
                        <Tab>Skills for tool</Tab>
                     </TabList>
                     <TabPanel>
                        <div className="grid-actions-wrapper">
                           <div className='filter-element'>
                              <div className='input-wrapper'>
                                 <label htmlFor='grid-filter-input' className='search-icon-wrapper'>
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                 </label>
                                 <input id='grid-filter-input' type='text' placeholder='Search' ref={filterInputRef}
                                        onChange={applyFilter} autoComplete='off'/>
                                 <span className='clear-icon-wrapper' title='Clear filter' onClick={clearFilter}>
                                    <i className="fa-solid fa-xmark"></i></span>
                              </div>
                           </div>
                        </div>
                        <div className='custom-grid-wrapper'>
                           <div id='workshop-grid' ref={annotatorRef}></div>
                        </div>
                        <button className="button button-primary" disabled={checkIfSubmitButtonDisabled()}
                                onClick={() => sendToolAndSkillFeedback(true, authStore.userData.accessToken)}>Submit
                        </button>
                     </TabPanel>
                  </Tabs>
               </div>
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   );

   function applyFilter(event: any) {
      grid.current.setFilter([[{field: 'skillName', type: 'like', value: event.target.value },]]);
   }

   function clearFilter() {
      grid.current.clearFilter();
      if (filterInputRef && filterInputRef.current) {
         filterInputRef!.current!.value = '';
      }
   }

   function checkIfSubmitButtonDisabled() {
      return Array.from(skillsMap.values()).filter(v => v === 'choose').length > 0;
   }

   function initializeComponent(accessToken: string) {
      const annotatorPanelURL = process.env.REACT_APP_PUBLIC_URL + "/annotator/annotator-panel";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .get(annotatorPanelURL, {headers})
         .then((response$: AxiosResponse<AiAnnotatingToolsDataDto>) => {
            setToolAndSkills(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function sendToolAndSkillFeedback(isTool: boolean, accessToken: string) {
      const annotatorPanelURL = process.env.REACT_APP_PUBLIC_URL + "/annotator/annotate-tool";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data: AnnotatedToolDataRequestDto = {
         toolName: toolAndSkills.toolName,
         category: toolAndSkills.category,
         annotatedSkills: isTool ? Array.from(skillsMap.keys()).map(sId => ({
            skillAiId:  sId,
            annotationBool: skillsMap.get(sId) === 'yes'
         })) : [],
         isTool
      }

      axios
         .post(annotatorPanelURL, data, {headers})
         .then((response$: AxiosResponse<AiAnnotatingToolsDataDto>) => {
            setToolAndSkills(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendToolAndSkillFeedback(isTool, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default AnnotatorPage;