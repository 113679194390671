import React, {useContext, useEffect, useState} from "react";
import style from './AIImpactScorePage.module.scss';
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import RadialSeparators from "../../../../../utils/RadialSeparators";
import ReactSlidingPane from "react-sliding-pane";
import Tooltip from "../../../../../services/tooltip.service";
import AuthContext from "../../../../../store/auth-context";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {
   AugmentationReportDataRepresentationDto,
   AugmentationSkillsRepresentationDto,
   QuestionnaireSessionStatusEnum,
   SkillDto,
   ToolDto,
   ToolFeedbackUses,
   ToolInformation
} from "../../../../../types/AugmentationData";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import useResponsiveComponent from "../../../../../ui/ResponsiveComponent/ResponsiveComponent";
import {BreakingPageWidths} from "../../../../../types/constants/BreakingWidths";
import {LinearProgress} from "@mui/material";
import GaugeComponent from "react-gauge-component";
import {animated, useTransition} from "react-spring";
import {ClipLoader} from "react-spinners";
import {createFadeStyles, createTransition} from "../../../../../utils/springStyles";
import {useNavigate} from "react-router-dom";

export enum ImpactScore {
   NOT_AUGMENTED_LIKED = 1,
   AUGMENTED_DISLIKED = 2,
   NOT_AUGMENTED_DISLIKED = 3,
   AUGMENTED_LIKED = 4,
}

const AIImpactScorePage: React.FC<{
   augmentationData: AugmentationReportDataRepresentationDto,
   sendCodeForWorkshop: (code: string, accessToken: string, navigation?: boolean) => void,
   subscribeToNews: (accessToken: string) => void,
   showToolModal: (tool: ToolDto) => void,
   selectedTool?: {tool: ToolDto, information?: ToolInformation, errorLoading: boolean, impactScore?: {old: number, new: number}}
}> = props => {

   const [selectedSection, setSelectedSection] = useState<ImpactScore>();
   const [showScoreResults, setShowScoreResults] = useState(false);
   const [rotation, setRotation] = useState({outer: 1, inner: 1});
   const [openPanel, setOpenPanel] = useState(true);
   const [activeSection, setActiveSection] = useState(1);
   const [showMoreTools, setShowMoreTools] = useState<{skill: SkillDto, showMore: boolean}[]>([]);
   const [workshopCode, setWorkshopCode] = useState("");
   const [expanded, setExpanded] = useState(false);
   const pageWidth = useResponsiveComponent();
   const navigate = useNavigate();

   const fadeInProcessing = createFadeStyles(250);
   const transitionTips = createTransition(activeSection === 1 && props.augmentationData.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED, 500, 0);
   const transitionCard = createTransition(activeSection === 2, 1000, 250);
   const transitionTitle = createTransition(activeSection === 2, 1000, 750);
   const transitionImage = createTransition(activeSection === 2, 1000, 1250);
   const transitionText = createTransition(activeSection === 2, 1000, 1500);

   const transitions = useTransition(expanded, {
      from: {maxHeight: 100, opacity: 0},
      enter: {maxHeight: 300, opacity: 1},
      leave: {maxHeight: 100, opacity: 0},
      config: {tension: 170, friction: 26},
      duration: 1000
   });

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      if (selectedSection) {
         setShowScoreResults(false);
         setOpenPanel(true);
      } else {
         if (!showScoreResults) {
            setOpenPanel(false);
         }
      }
      checkBorderRotationColoring();
   }, [selectedSection]);

   useEffect(() => {
      if (!openPanel) {
         setSelectedSection(undefined);
         setShowScoreResults(false);
      }
   }, [openPanel]);

   useEffect(() => {
      if (showScoreResults) {
         setSelectedSection(undefined);
         setOpenPanel(true);
      } else {
         if (!selectedSection) {
            setOpenPanel(false);
         }
      }
   }, [showScoreResults]);

   return (
      <>
         {
            props.augmentationData &&
            <div className={style["ai-impact-score-page"]}>
               {
                  props.augmentationData.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED ?
                     <>
                        {
                           transitionTips((style, isActive) => {
                              return (isActive ?
                                    <animated.div style={style}
                                                  className={`page-wrapper ${pageWidth.windowWidth <= BreakingPageWidths.mobile ? "mobile" : ""} active`}>
                                       <div className="ai-score-card">
                                          <div className="score-section">
                                             <h3 className="header-3">AI Impact Score</h3>
                                             <div className={"risk-factor"}>
                                                <div className="percentage">{props.augmentationData.aiImpactScore}%</div>
                                                <LinearProgress className={getProgressColor()} variant="determinate"
                                                                value={props.augmentationData.aiImpactScore}/>
                                                <div className="text-surface-30">{getProgressColor()}</div>
                                             </div>
                                             <i className="fa-solid fa-circle-info"
                                                onClick={() => setShowScoreResults(prevState => !prevState)}></i>
                                          </div>
                                          <button className="button button-black" onClick={() => setActiveSection(2)}>
                                             <i className="fa-solid fa-star"></i>
                                             <span>Tips and Recommendations</span>
                                          </button>
                                       </div>

                                       <section className="results-section">
                                          <div className="results-grid">
                                             <div
                                                className={`item item--1 liked-text ${checkActiveSectionText([ImpactScore.AUGMENTED_LIKED, ImpactScore.NOT_AUGMENTED_LIKED])}`}>
                                                <div className="text-wrapper">
                                                   <i className="fa-solid fa-heart"></i>
                                                   <span>liked</span>
                                                </div>
                                             </div>
                                             <div
                                                className={`item item--2 augmented-text ${checkActiveSectionText([ImpactScore.AUGMENTED_LIKED, ImpactScore.AUGMENTED_DISLIKED])}`}>
                                                <div className="text-wrapper reverse">
                                                   <div className="robot-wrapper">
                                                      <i className="fa-solid fa-robot"></i>
                                                   </div>
                                                   <span>augmented</span>
                                                </div>
                                             </div>
                                             <div
                                                className={`item item--3 ai-text ${checkActiveSection(ImpactScore.AUGMENTED_LIKED)}`}
                                                onClick={() => changeSection(ImpactScore.AUGMENTED_LIKED)}><span>AI for Your Favorite Tasks</span>
                                             </div>
                                             <div className="item item--4 teamo-image">
                                                <div className="image-wrapper">
                                                   <CircularProgressbarWithChildren value={selectedSection ? 17.5 : 0}
                                                                                    strokeWidth={5}
                                                                                    styles={buildStyles({
                                                                                       pathColor: '#A0A8E5',
                                                                                       trailColor: '#2F303C',
                                                                                       strokeLinecap: "butt",
                                                                                       rotation: rotation.outer
                                                                                    })}>
                                                      <div style={{width: "80%"}}>
                                                         <CircularProgressbarWithChildren value={selectedSection ? 25 : 0}
                                                                                          strokeWidth={3}
                                                                                          styles={buildStyles({
                                                                                             pathColor: '#A0A8E5',
                                                                                             trailColor: '#2F303C',
                                                                                             strokeLinecap: "butt",
                                                                                             rotation: rotation.inner
                                                                                          })}>
                                                            <RadialSeparators
                                                               count={4}
                                                               style={{
                                                                  background: "#0E0C19FF",
                                                                  width: "14px",
                                                                  // This needs to be equal to props.strokeWidth
                                                                  height: `${7}%`
                                                               }}
                                                            />
                                                            <div className={`teamo-wrapper ${getTeamoEyesPosition()}`}>
                                                               <img className={'teamo-image'}
                                                                    src="/assets/images/ai-impact-score-icons/teamo-no-eyes-icon.svg"
                                                                    alt="Teamo no eyes icon"/>
                                                               <img
                                                                  src="/assets/images/ai-impact-score-icons/teamo-eyes-icon.svg"
                                                                  alt="Teamo eyes icon" className="teamo-eyes-image"/>
                                                               <img
                                                                  src="/assets/images/ai-impact-score-icons/teamo-blue-eye-socket-icon.svg"
                                                                  alt="Teamo blue eye socket icon"
                                                                  className="teamo-eye-socket-image"/>
                                                            </div>
                                                         </CircularProgressbarWithChildren>
                                                      </div>
                                                   </CircularProgressbarWithChildren>
                                                </div>
                                             </div>
                                             <div
                                                className={`item item--5 ai-text ${checkActiveSection(ImpactScore.NOT_AUGMENTED_LIKED)}`}
                                                onClick={() => changeSection(ImpactScore.NOT_AUGMENTED_LIKED)}><span>Skills Untouched by AI</span>
                                             </div>
                                             <div
                                                className={`item item--6 augmented-text ${checkActiveSectionText([ImpactScore.NOT_AUGMENTED_LIKED, ImpactScore.NOT_AUGMENTED_DISLIKED])}`}>
                                                <div className="text-wrapper">
                                                   <div className="robot-wrapper">
                                                      <i className="fa-solid fa-slash"></i>
                                                      <i className="fa-solid fa-robot"></i>
                                                   </div>
                                                   <span>Not augmented</span>
                                                </div>
                                             </div>
                                             <div
                                                className={`item item--7 ai-text ${checkActiveSection(ImpactScore.AUGMENTED_DISLIKED)}`}
                                                onClick={() => changeSection(ImpactScore.AUGMENTED_DISLIKED)}><span>AI for Your Tedious Tasks</span>
                                             </div>
                                             <div
                                                className={`item item--8 ai-text ${checkActiveSection(ImpactScore.NOT_AUGMENTED_DISLIKED)}`}
                                                onClick={() => changeSection(ImpactScore.NOT_AUGMENTED_DISLIKED)}><span>AI Can't Help Here (Yet)</span>
                                             </div>
                                             <div
                                                className={`item item--9 liked-text ${checkActiveSectionText([ImpactScore.AUGMENTED_DISLIKED, ImpactScore.NOT_AUGMENTED_DISLIKED])}`}>
                                                <div className="text-wrapper">
                                                   <i className="fa-solid fa-heart-crack"></i>
                                                   <span>disliked</span>
                                                </div>
                                             </div>
                                          </div>
                                          {
                                             transitionTips((style, item) =>
                                                item ? (
                                                   <animated.div style={style} className="tips-section">
                                                      <img src="/assets/images/ai-impact-score-icons/teamo-wand-icon.svg"
                                                           alt="Teamo wand"/>
                                                      <img src="/assets/images/ai-impact-score-icons/cloud-tips-icon.svg"
                                                           alt="Cloud tips"/>
                                                   </animated.div>
                                                ) : null
                                             )
                                          }
                                       </section>

                                       {
                                          props.augmentationData.workshopVisibility &&
                                          <div className={`teamo-form ${expanded ? "expanded" : ""}`}>
                                             <div className="header">
                                                <img src="/assets/images/ai-impact-score-icons/teamo-thinking-icon.svg"
                                                     alt="Teamo thinking image"/>
                                                <h4 className="header-4">Join the Workshop Group!</h4>
                                                <i className="fa-solid fa-chevron-up"
                                                   onClick={() => setExpanded(!expanded)}></i>
                                             </div>
                                             {transitions(
                                                (styles, item) =>
                                                   item && (
                                                      <animated.div style={styles} className="body">
                                                         <p>Enter the code shared with you to unlock special features and explore your connections with the group.</p>
                                                         {
                                                            !authStore.userData.code ?
                                                            <div className="workshop-code">
                                                               <input value={workshopCode}
                                                                      onChange={e => setWorkshopCode(e.target.value)}
                                                                      type="text" className={"custom-input"}
                                                                      placeholder={"Enter workshop code"}/>
                                                               <button className={"button button-white"} onClick={openWorkshop}>Send code</button>
                                                            </div> : <button className={"button button-white"} onClick={() => navigate("/augmentation/augmentation-report/workshop")}>Go to workshop</button>
                                                         }
                                                      </animated.div>
                                                   )
                                             )}
                                          </div>
                                       }
                                    </animated.div>
                                    : null
                              );
                           })
                        }
                        {
                           activeSection === 2 &&
                           <div
                              className={`page-wrapper ${pageWidth.windowWidth <= BreakingPageWidths.mobile ? "mobile" : ""} not-active`}>
                              <div className="subscribe-wrapper">
                                 {
                                    transitionCard((style, item) =>
                                       item ? (
                                          <animated.div style={style} className="subscribe-card">
                                             {
                                                transitionTitle((style, item) =>
                                                   item ? (
                                                      <animated.div style={style} className="title">
                                                         <i className="fa-solid fa-star"></i>
                                                         <h2 className="header-2">Tips and Recommendations</h2>
                                                         <i className="fa-solid fa-xmark"
                                                            onClick={() => setActiveSection(1)}></i>
                                                      </animated.div>
                                                   ) : null
                                                )
                                             }
                                             {
                                                transitionTitle((style, item) =>
                                                   item ? (
                                                      <animated.p style={style}
                                                                  className="text-surface-30">{getWhatToDoNextText()[0]}</animated.p>
                                                   ) : null
                                                )
                                             }
                                             {transitionImage((style, item) =>
                                                item ? (
                                                   <animated.div style={style}>
                                                      <img
                                                         src="/assets/images/ai-impact-score-icons/teamo-stars-icon.svg"
                                                         alt="Teamo stars"/>
                                                   </animated.div>
                                                ) : null
                                             )}
                                             {transitionText((style, item) =>
                                                item ? (
                                                   <animated.p style={style}>{getWhatToDoNextText()[1]}</animated.p>
                                                ) : null
                                             )}
                                             {
                                                !props.augmentationData.augmentationNotification &&
                                                transitionText((style, item) =>
                                                   item ? (
                                                      <animated.button style={style}
                                                                       className="button button-primary"
                                                                       onClick={() => props.subscribeToNews(authStore.userData.accessToken)}>Notify
                                                         Me</animated.button>
                                                   ) : null
                                                )
                                             }
                                          </animated.div>
                                       ) : null
                                    )
                                 }
                                 {
                                    props.augmentationData.augmentationNotification &&
                                    transitionText((style, item) =>
                                       item ? (
                                          <animated.div style={style} className="already-notified">
                                             <p>{getWhatToDoNextText()[2]}</p>
                                          </animated.div>
                                       ) : null
                                    )
                                 }
                              </div>
                           </div>
                        }
                     </>
                     : props.augmentationData.sessionStatus === QuestionnaireSessionStatusEnum.PROCESSING ?
                        <animated.div className="pi-unfinished-main" style={fadeInProcessing}>
                           <div className="text-container">
                              <div className={"wrapper"}>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-waiting-icon.svg"
                                      alt="Teamo waiting icon"/>
                                 <div>
                                    <h2 className="header-2">Just a moment, Teamo is analyzing your data.</h2>
                                    <div className="u-margin-top-m">
                                       <p>Our AI is analyzing your data.</p>
                                       <p>Your personalized insights will be ready in just a moment!</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </animated.div> :
                        <animated.div className="pi-unfinished-main" style={fadeInProcessing}>
                           <div className="text-container">
                              <div className={"wrapper"}>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-die-icon.svg"
                                      alt="Teamo waiting icon"/>
                                 <div>
                                    <h2 className="header-2">Oops! There was an error.</h2>
                                    <div className="u-margin-top-m">
                                       <p>We're sorry, we ran into an issue.</p>
                                       <p>Please reach out to our support team at support@teamlift.co and we'll help you out.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </animated.div>
               }
            </div>
         }
         <ReactSlidingPane isOpen={openPanel}
                           onRequestClose={() => setOpenPanel(false)}
                           from={'right'}
                           className={`augmentation-panel`}
                           width='40%' hideHeader
                           overlayClassName={"augmentation__overlay"}>
            <div className="panel-content">
               <div className="panel-header">
                  {showScoreResults ? <h2 className="header-2">AI Impact Score Results</h2> : getImageAndTitle()}
                  <i className="fa-solid fa-xmark" onClick={() => setOpenPanel(false)}></i>
               </div>
               {
                  showScoreResults ?
                     <div className={"score-section"}>
                        <div className="gauge-wrapper">
                           <GaugeComponent
                              style={{width: '100%', height: '100%'}} // Make the gauge fill its container
                              arc={{
                                 cornerRadius: 1,
                                 width: 0.2,
                                 subArcs: [
                                    {
                                       limit: 15,
                                       color: '#A2CEB4FF',
                                       showTick: true
                                    },
                                    {
                                       limit: 35,
                                       color: '#F8D38FFF',
                                       showTick: true
                                    },
                                    {
                                       limit: 100,
                                       color: '#F2AC9DFF',
                                       showTick: true
                                    },
                                 ],
                              }}
                              value={props.augmentationData ? props.augmentationData.aiImpactScore : 0}
                              labels={{
                                 valueLabel: {hide: true}
                              }}
                           />
                           <div className="gauge-label">
                              <h2
                                 className={"gauge-percentage header-2"}>{props.augmentationData ? props.augmentationData.aiImpactScore + "%" : "0%"}</h2>
                              <div className={"gauge-type"}>{getProgressColor()}</div>
                           </div>
                        </div>

                        <p className="gauge-text">{getAugmentationText()}</p>

                     </div>
                     :
                     <>
                        <h3 className="header-3">{getPanelText()}</h3>
                        {getTeamoImageAndTitle()}
                        {getSkillsAndTools()}
                     </>
               }
            </div>
         </ReactSlidingPane>
         {
            spinnerService.spinner
         }
      </>
   );

   function openWorkshop() {
      props.sendCodeForWorkshop(workshopCode, authStore.userData.accessToken, true);
      setOpenPanel(false);
      setSelectedSection(undefined);
   }

   function getAugmentationText() {
      let title = '';
      let subtitle = '';
      if (props.augmentationData) {
         if (props.augmentationData.aiImpactScore <= 15) {
            title = 'AI’s impact is minimal right now. 🛠️';
            subtitle = 'You’re well-positioned to stay ahead with the right strategy and tools, ensuring you’re ready for future opportunities.';
         } else if (props.augmentationData.aiImpactScore <= 35) {
            title = 'Your AI Influence is increasing. 🚀';
            subtitle = 'This is the perfect time to jump in, enhance your efficiency, and fully capitalize on this opportunity for better and more effective work.';
         } else {
            title = 'Your AI Impact Score is high! 🚀';
            subtitle = 'This means we’ve identified significant opportunities to enhance your efficiency at work.';
         }
      }
      return <><b>{title}</b><br/><span className="text-surface-30">{subtitle}</span></>;
   }

   function getWhatToDoNextText() {
      if (props.augmentationData) {
         if (props.augmentationData.aiImpactScore <= 15) {
            return [
               'You’re ahead of the AI landscape at the moment! Keeping yourself there shouldn’t be too difficult, as long as you\'re aware of the latest AI advancements and tools!',
               'Since the AI landscape is always changing, it\'s important to stay updated on your AI impact score. If your score changes, would you like us to notify you?',
               'We’ll notify you when there’s been an update to your AI Impact Score.'
            ];
         } else if (props.augmentationData.aiImpactScore <= 35) {
            return [
               'Make AI work for you, not against you! Incorporating AI tools into your workflow sooner rather than later can keep you ahead of your peers in this evolving landscape.',
               'We\'re collecting information on how other people like you are using these tools. Would you like us to notify you when it’s ready for sharing?',
               'We’ll notify you when the information is ready for sharing.'
            ];
         } else {
            return [
               'While AI can handle many tasks, there are certain areas where human abilities remain irreplaceable.',
               'We can identify skills and courses related to your strengths that are both in growing demand and at lower risk of AI influence! Would you like us to notify you when it\'s ready?',
               'We’ll notify you when the skills and courses are ready.'
            ]
         }
      }
      return [];
   }

   function getProgressColor() {
      if (props.augmentationData) {
         if (props.augmentationData.aiImpactScore <= 15) {
            return 'low';
         } else if (props.augmentationData.aiImpactScore <= 35) {
            return 'medium';
         } else {
            return 'high';
         }
      }
      return '';
   }

   function getTeamoEyesPosition() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return "bottom-right"
         case ImpactScore.AUGMENTED_DISLIKED:
            return "bottom-left"
         case ImpactScore.AUGMENTED_LIKED:
            return "top-left"
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return "top-right"
         default:
            return "";
      }
   }

   function getSkillsAndTools() {

      const renderSkill = (skill: AugmentationSkillsRepresentationDto) => {
         return (
            <div className="custom-light-skill" key={skill.skill.id}>
               <span>{capitalizeFirstLetter(skill.skill.name)}</span>
               <Tooltip key={`skill_${skill.skill.id}`} tooltipId={`skill_${skill.skill.id}`} place={"top"}
                        className={"custom-tooltip"} content={skill.skill.description}>
                  <i className="fa-regular fa-circle-question"></i>
               </Tooltip>
            </div>
         );
      }

      const renderTools = (tools: ToolDto[]) => {
         return (
            sortToolsByFeedback(tools).map(tool => {
               return (
                  <div className="custom-blue-tool" key={tool.toolId} onClick={() => props.showToolModal(tool)}>
                     <span>{tool.name}</span>
                     {showFeedbackType(tool)}
                     {
                        props.selectedTool && props.selectedTool.tool === tool && !props.selectedTool.information &&
                        <ClipLoader size={16} color={"#F1F6FBFF"} />
                     }
                  </div>
               )
            })
         )
      }

      const renderSkillWithTools = (skill: AugmentationSkillsRepresentationDto) => {
         return (
            <div className="skill-tools-section" key={skill.skill.id}>
               <div className="skill-wrapper">{renderSkill(skill)}</div>
               <div className="tools-wrapper">
                  {
                     skill.tools && (showMoreTools.find(s => s.skill === skill.skill)?.showMore ?
                        <>
                           {renderTools(skill.tools)}
                           {skill.tools.length > 3 && <div onClick={() => showMoreToolsFunction(skill.skill)} key={skill.skill.id} className="more-tools">hide tools</div>}
                        </> :
                           <>
                              {renderTools(skill.tools).slice(0,3)}
                              {skill.tools.length > 3 &&
                                 <div onClick={() => showMoreToolsFunction(skill.skill)}
                                      className="more-tools" key={skill.skill.id}>+{skill.tools.length - 3} other
                                    tool{skill.tools.length - 3 === 1 ? "" : "s"}</div>}
                           </>
                     )
                  }
               </div>
            </div>
         )
      }

      const showMoreToolsFunction = (skill: SkillDto) => {
         setShowMoreTools(prevState => prevState
            .map(s => s.skill === skill ? ({...s, showMore: !s.showMore}) : s));
      }

      const customSortOrder = [ToolFeedbackUses.ALREADY_USING, ToolFeedbackUses.START_USING, undefined, ToolFeedbackUses.IS_NOT_FOR_ME];

      function sortToolsByFeedback(tools: ToolDto[]) {
         return tools.sort((t1, t2) => customSortOrder.indexOf(t1.feedback?.feedback) - customSortOrder.indexOf(t2.feedback?.feedback));
      }

      function showFeedbackType(tool: ToolDto) {
         if (!tool.feedback) return;

         switch (tool.feedback.feedback) {
            case ToolFeedbackUses.ALREADY_USING:
               return <i className="fa-regular fa-circle-check text-green-40"></i>;
            case ToolFeedbackUses.START_USING:
               return <i className="fa-regular fa-clock text-yellow-40"></i>;
            default:
               return <i className="fa-regular fa-circle-xmark text-red-40"></i>;
         }
      }


      switch (selectedSection) {

         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return <div
               className="skills-section">{props.augmentationData && props.augmentationData.disLikeableNonAugmentedSkills.map(renderSkill)}</div>

         case ImpactScore.AUGMENTED_DISLIKED:
            return <div
               className="skills-tools-wrapper">{props.augmentationData && props.augmentationData.disLikeableAugmentedSkills.map(renderSkillWithTools)}</div>

         case ImpactScore.AUGMENTED_LIKED:
            return <div
               className="skills-tools-wrapper">{props.augmentationData && props.augmentationData.likeableAugmentedSkills.map(renderSkillWithTools)}</div>

         case ImpactScore.NOT_AUGMENTED_LIKED:
            return <div
               className="skills-section">{props.augmentationData && props.augmentationData.likeableNonAugmentedSkills.map(renderSkill)}</div>

         default:
            return <></>;
      }
   }

   function getPanelText() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart-crack"></i> Disliked,</span>
                  <span><span className="robot-wrapper">
                                       <i className="fa-solid fa-slash"></i>
                                       <i className="fa-solid fa-robot"></i>
                                    </span> Not Augmented with AI</span>
               </div>
            )
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart-crack"></i> Disliked,</span>
                  <span><i className="fa-solid fa-robot"></i> Augmented with AI</span>
               </div>
            )
         case ImpactScore.AUGMENTED_LIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart"></i> Liked,</span>
                  <span><i className="fa-solid fa-robot"></i> Augmented with AI</span>
               </div>
            )
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart"></i> Liked,</span>
                  <span><span className="robot-wrapper">
                                       <i className="fa-solid fa-slash"></i>
                                       <i className="fa-solid fa-robot"></i>
                                    </span> Not Augmented with AI</span>
               </div>
            )
         default:
            return "";
      }
   }

   function getImageAndTitle() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/tasks-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Your Energy Drainers</h2></div>)
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/tasks-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Alleviate Tedious Tasks</h2></div>)
         case ImpactScore.AUGMENTED_LIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/wings-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">AI-Amplified Passions</h2></div>)
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/wings-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Your Human Superpowers</h2></div>)
         default:
            return "";
      }
   }

   function getTeamoImageAndTitle() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               props.augmentationData?.disLikeableNonAugmentedSkills && props.augmentationData.disLikeableNonAugmentedSkills.length > 0 ?
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-sad-icon.svg" alt="Teamo hands icon"/>
                     <p>These are your highlighted <span className="custom-light-skill">skills</span> that we can't find <span className="custom-blue-tool no-action">ai tools</span> for yet.</p>
                  </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               props.augmentationData?.disLikeableAugmentedSkills && props.augmentationData.disLikeableAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                       alt="Teamo hands icon"/>
                  <div>
                     <p>These are your disliked <span className="custom-light-skill">skills</span> that can be augmented with <span
                        className="custom-blue-tool no-action">ai tools</span></p>
                     <p className={'u-margin-top-xs'}>Click on a <span className="custom-blue-tool no-action">tool</span> to learn more and share your feedback!</p>
                  </div>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.AUGMENTED_LIKED:
            return (
               props.augmentationData?.likeableAugmentedSkills && props.augmentationData.likeableAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-small-hands-icon.svg" alt="Teamo hands icon"/>
                  <div>
                     <p>These are your <span className="custom-light-skill">skills</span> that these <span className="custom-blue-tool no-action">ai tools</span> might boost.</p>
                     <p className={'u-margin-top-xs'}>Click on a <span className="custom-blue-tool no-action">tool</span> to learn more and share your feedback!</p>
                  </div>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               props.augmentationData?.likeableNonAugmentedSkills && props.augmentationData.likeableNonAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                       alt="Teamo hands icon"/>
                  <p>These are your liked <span className="custom-light-skill">skills</span> that no AI tool can replace (for now)!
                  </p>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         default:
            return "";
      }
   }

   function changeSection(section: ImpactScore) {
      if (selectedSection === section) {
         setSelectedSection(undefined);
         setShowMoreTools([]);
      } else {
         switch (section) {
            case ImpactScore.AUGMENTED_LIKED:
               setShowMoreTools(props.augmentationData!.likeableAugmentedSkills.map(augmentedSkill => ({skill: augmentedSkill.skill, showMore: false})));
               break;
            case ImpactScore.AUGMENTED_DISLIKED:
               setShowMoreTools(props.augmentationData!.disLikeableAugmentedSkills.map(augmentedSkill => ({skill: augmentedSkill.skill, showMore: false})));
               break;
            default:
               setShowMoreTools([]);
         }
         setSelectedSection(section);
      }
   }

   function checkActiveSection(section: ImpactScore) {
      if (selectedSection) {
         if (selectedSection.valueOf() === section.valueOf()) {
            return "ai-selected";
         }
         return "ai-not-selected";
      }
      return "";
   }

   function checkActiveSectionText(sections: ImpactScore[]) {
      if (selectedSection && sections.includes(selectedSection)) {
         return "text-primary-20";
      }
      return "";
   }

   function checkBorderRotationColoring() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            setRotation({outer: .3, inner: .25})
            return;
         case ImpactScore.AUGMENTED_DISLIKED:
            setRotation({outer: .525, inner: .5})
            return;
         case ImpactScore.AUGMENTED_LIKED:
            setRotation({outer: .8, inner: .75});
            return;
         case ImpactScore.NOT_AUGMENTED_LIKED:
            setRotation({outer: 1.025, inner: 1})
            return;
         default:
            return;
      }
   }

}

export default AIImpactScorePage;