import React, { useEffect, useState, useContext } from 'react';

import styles from './LoginPage.module.scss';

import createCanvas from '../../utils/implement-canvas-bg';

import LoginForm from './LoginFormComponent/LoginFormComponent';
import AuthContext from '../../store/auth-context';

import useApiService from '../../services/api.service';
import {ErrorResponseDto} from "../../types/ErrorData";
import {LoginData} from "../../types/LoginRegisterData";

const LoginPage: React.FC<{}> = props => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [error, setError] = useState("");
 
  const authStore = useContext(AuthContext);
  const { tryLogin } = useApiService();

  // Initializing the component
  useEffect(() => {
    // Create animated canvas background on login page
    createCanvas();
  }, []);

  return (
    <div className={styles['login-page']}>
      <div className='main-container'>
        {/* Canvas background */}
        <canvas id="nokey" width="800" height="800" className='d-none d-md-block'></canvas>
        <div className={`card login`}>
          <div className='card-header'>
            <img src="/assets/images/global-icons/teamlift-logo-white.svg" className="logo" alt="logo" />
          </div>
           <div className='card-body'>
              <LoginForm submitFormFunction={submitForm} isDataLoading={isDataLoading} error={error} />
          </div>
        </div>
      </div>
    </div>
  );

  // Submit function for login
  function submitForm(data: LoginData) {
    setIsDataLoading(true);

    tryLogin(data.username, data.password)
       .then((res$: any) => {
         setIsDataLoading(false);
         // Set the user tokens and menu structure in the auth store
         authStore.login(res$['data']['data']);
       })
       .catch((error$: ErrorResponseDto) => {
         setIsDataLoading(false);
         if (error$.response.data && error$.response.data.statusCode === 400 || error$.response.data && error$.response.data.statusCode === 404) {
           setError(error$.response.data.message);
         }
         else {
           setError("Unable to login!");
         }
       });
  }
}

export default LoginPage;